.panel_width {
    width: 100%;
}

.filter_panel {
    margin-top: 30px;
    padding: 20px;
    background-color: #111349;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
    opacity: 0.9;
}

.filter_panel_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.filterHeaderLeft {
    display: flex;
    align-items: anchor-center;
    gap: 10px;
}

.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
}

.toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.3s;
}

.slider:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

/* Включённый режим */
.toggleSwitch input:checked+.slider {
    background-color: #484848;
}

.toggleSwitch input:checked+.slider:before {
    transform: translateX(24px);
}

.cartButton {
    background-color: #111349;
    color: white;
    border: none;
    padding: 8px 18px;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.cartButton:hover {
    background-color: #0d0f3b;
}

h3 {
    font-size: 22px;
    font-weight: bold;
}

.filter_panel_menu {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.filter_panel_body {
    width: 100%;
    margin-top: 30px;
    background-color: #111349;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
    opacity: 0.9;
}

.category_item {
    background-color: white;
    color: #111349;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.active {
    background-color: #111349;
    color: white;
}

.filter_panel_menuChoices {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.panel_menChoices_shaffle {
    background-color: white;
    color: #111349;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.activeSubcategory {
    background-color: #111349;
    color: white;
}


/* Контейнеры все */
.container {
    margin-top: 40px;
    display: flex;
    gap: 40px;
    margin-left: 100px;
}

.productsRow {
    display: grid;
    flex-wrap: nowrap;
    gap: 20px;
    align-items: flex-start;
    width: 100%;
}

.product_search {
    position: relative;
    flex-shrink: 0;
}

.product_searchInput {
    width: 100%;
    padding: 10px 40px 10px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    height: 50px;
    box-sizing: border-box;
}

.searchIconInside {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;

    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 4px;

    padding: 6px;
    box-sizing: border-box;

    opacity: 0.8;
    cursor: pointer;
    /* ✅ курсор как у кнопки */
    transition: all 0.2s ease;
}

.searchIconInside:hover {
    background-color: #e9e9e9;
    opacity: 1;
    border-color: #999;
}

.productsList {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.searchIconImage {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.sideBar {
    width: 450px;
    min-height: 100px;
    border: 2px solid #ddd;
    padding: 15px;
    background-color: rgba(249, 249, 249, 0.9);
}


/* Адаптивность для планшетов */
@media (max-width: 768px) {
    .productsRow {
        flex-direction: column;
    }

    .product_search {
        width: 100%;
    }

    .container {
        margin-top: 40px;
        display: flex;
        gap: 5px;
    }

    .product_search {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        margin-left: 30px;
    }

    .sideBar {
        font-size: 15px;
    }
}