.container {
    display: flex;
    background-color: #eae6df;
}

.authWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
}