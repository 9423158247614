.optionBox {
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdownButton {
    width: 100%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.dropdownButton:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.dropdownButtonNumber {
    width: 70%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.dropdownButtonNumber:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.dropdownButtonList {
    width: 20%;
    background: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    background-color: #111349;
}

.dropdownButtonList:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.dropdownList {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #c7c7c7;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #111349 #c7c7c7;
}

.dropdownList li {
    padding: 10px;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdownList li:hover {
    background-color: #111349;
    color: #ffffff;
}

@media only screen and (max-width: 768px) {
    .savedText {
        width: 370px;
    }

    .mailingContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: none;
    }

    .serviceContainer {
        width: 300px;
    }

    .savedTextContainers {
        width: 400px;
    }
}