.mainArea {
    flex: 1;
    display: flex;
    background-color: #f0f2f5;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.placeholder {
    text-align: center;
    color: #3b4a54;
    max-width: 400px;
}

.image {
    width: 250px;
    margin-bottom: 24px;
    opacity: 0.9;
}

.button {
    margin-top: 20px;
    padding: 10px 24px;
    font-size: 16px;
    border: none;
    background-color: #25d366;
    color: white;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #20c55a;
}

.chatArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chatHeader {
    padding: 12px 16px;
    background: #f0f2f5;
    border-bottom: 1px solid #ccc;
    font-weight: 500;
    font-size: 16px;
}

.messages {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    background-color: #efeae2;
}

.message {
    padding: 8px 14px;
    border-radius: 8px;
    max-width: 70%;
    font-size: 14px;
    word-break: break-word;
    line-height: 1.4;
}

.mine {
    background-color: #d9fdd3;
    align-self: flex-end;
}

.their {
    background-color: #ffffff;
    align-self: flex-start;
}

.placeholder {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
}

.inputArea {
    display: flex;
    padding: 10px 16px;
    border-top: 1px solid #ccc;
    background-color: #f0f2f5;
    align-items: center;
    gap: 8px;
}

.inputField {
    flex: 1;
    padding: 10px 14px;
    border-radius: 20px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 14px;
}

.sendButton {
    padding: 10px 16px;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.sendButton:hover {
    background-color: #20c55a;
}