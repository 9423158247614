.sidebar {
    width: 400px;
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.actions button {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
}

.searchBar {
    padding: 8px 12px;
    margin: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    outline: none;
}

.filterTabs {
    display: flex;
    gap: 8px;
    padding: 10px;
}

.filterTabs button {
    border: none;
    background: #f1f3f4;
    border-radius: 20px;
    padding: 6px 12px;
    cursor: pointer;
}

.filterTabs button.active {
    background-color: #25d366;
    color: #fff;
}

.chatList {
    height: 300px;
    overflow-y: auto;
    padding: 5px;
    overflow-x: hidden;
}

.chatItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.2s;
}

.chatItem:hover {
    background-color: #f5f5f5;
}

.chatItem div p {
    color: #888;
    font-size: 14px;
}

.unread {
    color: white;
    background-color: #25d366;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
}